var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { components } from "react-select";
import { DropDownIndicatore } from "../DropDownIndicatore";
import { FilterTitle } from "../SideFilter/MultiSelection/MultiSelection-styles";
import { LabelColor } from "../SideFilter/MultiSelection/MultiSelection-styles";
import { useTranslation } from "react-i18next";
import { AsyncPaginate } from "react-select-async-paginate";
var RemoteMultiSelect = function (props) {
    var _a;
    var relatedField = props.relatedField, ESindex = props.ESindex, column = props.column, operator = props.operator, valueColumn = props.valueColumn, getCustomLabel = props.getCustomLabel, customFilterType = props.customFilterType;
    var others = props.others;
    var hasColors = props.colors;
    var pageSize = others ? 99999999 : 5;
    var t = useTranslation(["multi-selection"]).t;
    var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
    // eslint-disable-next-line no-unused-vars
    var _b = __read(React.useState([]), 2), initialOptions = _b[0], setInitialOptions = _b[1];
    var _c = __read(React.useState(false), 2), loading = _c[0], setLoading = _c[1];
    /**
     * This function is called when the user types in the search box.
     * We load options from the API and push them to the initial options.
     * We also reset the page to 1.
     *
     * @param {string} [inputValue=""]
     * @param {boolean} [nextPage=false]
     * @return {*}
     */
    var loadOptions = function (inputValue, prevOptions, _a) {
        if (inputValue === void 0) { inputValue = ""; }
        var _b = _a === void 0 ? {} : _a, _c = _b.page, page = _c === void 0 ? 1 : _c;
        return __awaiter(void 0, void 0, void 0, function () {
            var column_query, currentUrl, pathname, pathSegments, typeCategorieValue, defaultCompanyTypes, c, orderIfScian, response, data, othersData_1, newOptions, error_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        setLoading(true);
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        column_query = "&".concat(props.query || "");
                        currentUrl = new URL(window.location.href);
                        pathname = currentUrl.pathname;
                        pathSegments = pathname.split("/").filter(function (segment) { return segment !== ""; });
                        typeCategorieValue = pathSegments[pathSegments.length - 1];
                        defaultCompanyTypes = {
                            providers: "supplier",
                            stakeholders: "intervenant",
                            contractors: "contractor",
                            //need french part also!
                            fournisseurs: "supplier",
                            intervenants: "intervenant",
                            contracteurs: "contractor",
                        };
                        if (Array.isArray(column)) {
                            for (c in column) {
                                if (customFilterType === "scian") {
                                    if (isNaN(inputValue) && column[c] == "title") {
                                        column_query += "&".concat(column[c], "__").concat(operator[c], "=").concat(inputValue);
                                    }
                                    else if (column[c] == "is_visible") {
                                        column_query += "&".concat(column[c], "__").concat(operator[c], "=True");
                                    }
                                    else if (!isNaN(inputValue) && column[c] == "code") {
                                        column_query += "&".concat(column[c], "__").concat(operator[c], "=").concat(inputValue);
                                    }
                                }
                                else {
                                    column_query += "&".concat(column[c], "__").concat(operator[c], "=").concat(inputValue);
                                    if (typeCategorieValue in defaultCompanyTypes) {
                                        column_query += "".concat(defaultCompanyTypes[typeCategorieValue]);
                                    }
                                }
                            }
                        }
                        else {
                            column_query += "&".concat(column, "__").concat(operator, "=").concat(inputValue);
                            if (column == "type_categorie") {
                                column_query += "&".concat(column, "__").concat(operator, "=contractor");
                            }
                        }
                        orderIfScian = "";
                        if (relatedField.includes("code_scian")) {
                            orderIfScian = "&order=code";
                        }
                        return [4 /*yield*/, fetch("/".concat(langCode, "/api/search/related-field-model/?field=").concat(relatedField).concat(orderIfScian, "&document=").concat(ESindex, "&page_size=").concat(pageSize, "&page=").concat(page).concat(column_query))];
                    case 2:
                        response = _d.sent();
                        return [4 /*yield*/, response.json()];
                    case 3:
                        data = _d.sent();
                        othersData_1 = null;
                        if (others) {
                            othersData_1 = [];
                            othersData_1 = data.results.filter(function (item) {
                                // Check if every condition in others list is met
                                return others.every(function (condition) {
                                    // Check if item satisfies the condition
                                    return item[condition.column] === condition.value;
                                });
                            }).map(function (item) { return item.id; });
                            // Remove items that satisfy all conditions from data array
                            data.results = data.results.filter(function (item) { return !othersData_1.includes(item.id); });
                        }
                        newOptions = data.results.map(function (item) { return ({
                            value: getOptionValue(item),
                            label: getOptionLabel(item),
                            color: hasColors ? item.color : null,
                        }); });
                        if (othersData_1) {
                            // eslint-disable-next-line no-debugger
                            newOptions = newOptions.concat({
                                value: othersData_1,
                                label: t("Others"),
                                color: null,
                            });
                        }
                        return [2 /*return*/, {
                                options: newOptions,
                                hasMore: data.next !== null,
                                additional: {
                                    page: page + 1,
                                },
                            }];
                    case 4:
                        error_1 = _d.sent();
                        console.log(prevOptions);
                        console.error("An error occurred while fetching data:", error_1);
                        setLoading(false);
                        return [2 /*return*/, []];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * This function is called when the user scrolls to the bottom of the menu.
     * We load more options from the API and push them to the initial options.
     */
    var handleScrollToBottom = function () {
        if (!loading) {
            loadOptions("", true);
        }
    };
    React.useEffect(function () {
        loadOptions();
    }, []);
    /**
     * This function is to set the label of the option.
     *
     * @param {*} option
     * @return {*}
     */
    var getOptionLabel = function (option) {
        return getCustomLabel ? getCustomLabel(option, langCode) : option.id;
    };
    /**
     * This function used to set the value of the option.
     *
     * @param {*} option
     * @return {*}
     */
    var getOptionValue = function (option) {
        return option[valueColumn] || option["id"];
    };
    var MultiValue = function (_a) {
        var index = _a.index, getValue = _a.getValue, props = __rest(_a, ["index", "getValue"]);
        var maxToShow = 1;
        var values = getValue();
        return index < maxToShow ? (_jsx(components.MultiValue, __assign({}, props, { children: props.children }))) : index === maxToShow ? (_jsxs(components.MultiValue, __assign({}, props, { children: [" ", "+ ", values.length - 1, " ", t("selected"), " "] }))) : null;
    };
    var customStyles = {
        multiValueLabel: function (provided) { return (__assign(__assign({}, provided), { backgroundColor: "#dae6f0", color: "#095797", fontWeight: "600", borderRadius: "0px", paddingTop: "3px", paddingBottom: "3px" })); },
        multiValueRemove: function (provided) { return (__assign(__assign({}, provided), { backgroundColor: "#dae6f0", color: "#095797", borderRadius: "0", cursor: "pointer", width: "32px", paddingTop: "3px", paddingLeft: "5px", "&:hover": {
                backgroundColor: "#dae6f0",
                color: "red",
            } })); },
        placeholder: function (provided) { return (__assign(__assign({}, provided), { fontSize: "1rem", color: "#647287" })); },
        menuList: function (provided) { return (__assign(__assign({}, provided), { maxHeight: 175, minHeight: 175, "::-webkit-scrollbar": {
                width: "6px",
            }, "::-webkit-scrollbar-thumb": {
                backgroundColor: "#888",
            }, "::-webkit-scrollbar-track": {
                backgroundColor: "#f1f1f1",
            }, scrollBehavior: "smooth" })); },
    };
    var MultiValueRemove = function (props) {
        return (_jsx(components.MultiValueRemove, __assign({}, props, { children: _jsx("img", { src: "".concat(process.env.IMG_URL, "/static/vendors/images/icons/x-icon.svg"), alt: "close" }) })));
    };
    var Option = function (props) {
        return (_jsx("div", { children: _jsx(components.Option, __assign({}, props, { children: _jsxs("div", __assign({ className: "d-flex align-items-top" }, { children: [hasColors && (_jsx(LabelColor, { className: "label-color", style: { backgroundColor: props.data.color } })), _jsx("input", { type: "checkbox", className: "me-2 mt-1", checked: props.isSelected, onChange: function () { return null; } }), _jsx("label", __assign({ style: { flex: 1 } }, { children: props.label }))] })) })) }));
    };
    var DropdownIndicator = function (props) {
        return (_jsx(components.DropdownIndicator, __assign({}, props, { children: _jsx(DropDownIndicatore, {}) })));
    };
    return (_jsxs("div", __assign({ style: { marginBottom: '32px' } }, { children: [_jsxs(FilterTitle, { children: [" ", t(props.title), " "] }), _jsx(AsyncPaginate, { defaultOptions: true, loadOptions: loadOptions, closeMenuOnSelect: false, classNamePrefix: "pn-select", isMulti: true, isClearable: false, hideSelectedOptions: false, onChange: props.handleClick, value: props.SelectedChoices, styles: customStyles, placeholder: props.placeHolder, isDisabled: props.disabled, loadingMessage: function () { return t("Loading..."); }, noOptionsMessage: function () { return t("No result found"); }, components: {
                    DropdownIndicator: DropdownIndicator,
                    MultiValue: MultiValue,
                    Option: Option,
                    MultiValueRemove: MultiValueRemove,
                    IndicatorSeparator: function () { return null; },
                }, menuIsScrollable: true, onMenuScrollToBottom: handleScrollToBottom, debounceTimeout: 1000, additional: {
                    page: 1,
                } })] })));
};
export default RemoteMultiSelect;
